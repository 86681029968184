import { BaseLogin } from './login';
import guard from '@/guard';

export class CloudLoginPage extends BaseLogin {
    test: { id: string };
    async created() {
        guard.checkAuthentication();
    }

}

