

import { DemoLoginPage } from './login.demo';
import { CloudLoginPage } from './login.cloud';

import { platform } from '@cems-eco/core/src/utils';
import environment from "@/config";

export default class LoginMobile extends platform.mixins(environment.nexus, {
    box: CloudLoginPage,
    cloud: CloudLoginPage,
    demo: DemoLoginPage
}) { }
