import { Login } from "../../services";
import authBloc from "../../services/bloc";
import { widget } from "@cems-eco/core/src/utils";
import { Validators } from "@cems-eco/core/src/utils/validator";
import guard from '@/guard';
import { Vue } from "vue-class-component";
import environment from "@/config";
import { ElLoading } from 'element-plus';

export class BaseLogin extends Vue {
    loginBloc = authBloc;
    rules: any = {
        email: { type: "email" },
        password: { type: "string", min: 1, }
    };

    login = new Validators<Login>({
        value: new Login,
        rules: this.rules
    });

    logo() {
        return environment.white_label.asset.logo;
    }

    async authenticate() {
        const loader = ElLoading.service({ lock: true });
        try {
            await guard.checkProviderStatus();
            const response = await this.loginBloc.login(this.login.value);
            await this.loginBloc.saveStorage(response);
            guard.checkAuthentication();
        } catch (e) {

            widget.alertError(e);
        }
        loader.close();

    }

}

