import { BaseLogin } from './login';
import { widget } from "@cems-eco/core/src/utils";
import router from "@/router";
import { ElLoading } from 'element-plus';

export class DemoLoginPage extends BaseLogin {
    test: { id: string };

    async created() {
        this.login.value.email = "admin@ada-ir.com";
        this.login.value.password = "password";
    }

    async authenticate() {
        const loader = ElLoading.service({ lock: true });
        try {
            router.replace('/auth/site');
            loader.close();
        } catch (e) {
            loader.close();
            widget.alertError(e);
        }
    }
}

