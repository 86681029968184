import { Vue } from 'vue-class-component';
import authBloc from "../../services/bloc";
import guard from '@/guard';

export default class AuthAutoLoginPage extends Vue {
    bloc = authBloc;
    async beforeCreate() {
        const key: string = this.$route.params.key as string;
        const response: any = await this.bloc.autoLogin(key);
        await this.bloc.saveStorage(response.auth);
        await this.bloc.site(response.site);
        guard.checkAuthentication();
    }

}

